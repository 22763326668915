<a *ngIf="!item.disabled"
   href="javascript:void(0);"
   class="nav-item nav-link"
   role="button"
   [title]="item.text | translate"
   (click)="activate($event)"
   (keyup.space)="activate($event)"
   (keyup.enter)="activate($event)"
>{{item.text | translate}}</a>
<span *ngIf="item.disabled"   class="nav-item nav-link disabled">{{item.text | translate}}</span>
