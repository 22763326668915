<ng-container *ngVar="selectionService?.isObjectSelected(selectionConfig?.listId, object) | async as checked">
    <input #selectListItemCheckbox *ngIf="selectionConfig.repeatable" class="form-check-input" type="checkbox"
           [attr.aria-label]="(selectListItemCheckbox.checked ? 'selectable-list-item-control.deselect' : 'selectable-list-item-control.select') | translate"
           [name]="'checkbox' + index"
           [id]="'object' + index"
           [ngModel]="selected$ | async"
           (ngModelChange)="selectCheckbox($event)">
    <input *ngIf="!selectionConfig.repeatable" class="form-check-input mt-2" type="radio"
           [attr.aria-label]="'selectable-list-item-control.select' | translate"
           [name]="'radio' + index"
           [id]="'object' + index"
           [checked]="selected$ | async"
           (click)="selectRadio(!checked)">
</ng-container>
