<table id="metadata" class="table table-striped table-hover table-responsive">
  <thead>
  <tr>
    <th scope="col">{{'item.edit.modify.overview.field'| translate}}</th>
    <th scope="col">{{'item.edit.modify.overview.value'| translate}}</th>
    <th scope="col">{{'item.edit.modify.overview.language'| translate}}</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let mdEntry of metadata | keyvalue">
    <tr *ngFor="let mdValue of mdEntry.value" class="metadata-row">
      <td>{{mdEntry.key}}</td>
      <td>{{mdValue.value}}</td>
      <td>{{mdValue.language}}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
