<div class="d-flex flex-row">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[]" [queryParams]="queryParams$ | async" [queryParamsHandling]="'merge'" class="lead">
        {{object.value}}
    </a>
    <span *ngIf="linkType == linkTypes.None" class="lead">
        {{object.value}}
    </span>
    <span class="pr-2">&nbsp;</span>
    <span class="badge badge-pill badge-secondary align-self-center">{{object.count}}</span>
</div>
